var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        staticClass: "flex flex-wrap",
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "w-full mb-4" },
          [
            _c("base-label", { attrs: { for: "name_" + _vm._uid } }, [
              _vm._v(" Name "),
            ]),
            _c("base-input", {
              staticClass: "shadow-none",
              attrs: {
                id: "name_" + _vm._uid,
                type: "text",
                placeholder: "Name of the animal",
              },
              model: {
                value: _vm.animalCopy.name,
                callback: function ($$v) {
                  _vm.$set(_vm.animalCopy, "name", $$v)
                },
                expression: "animalCopy.name",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "w-full mb-4" },
          [
            _c("base-label", { attrs: { for: "birthdate_" + _vm._uid } }, [
              _vm._v(" Birthdate "),
            ]),
            _c("Datepicker", {
              staticClass: "rounded border border-gray-light font-medium",
              attrs: {
                id: "birthdate_" + _vm._uid,
                format: "yyyy-MM-dd",
                "monday-first": true,
                "disabled-dates": _vm.disabledDates,
              },
              model: {
                value: _vm.animalBirthdate,
                callback: function ($$v) {
                  _vm.animalBirthdate = $$v
                },
                expression: "animalBirthdate",
              },
            }),
          ],
          1
        ),
        _vm.showAnimalType
          ? _c(
              "div",
              { staticClass: "w-full mb-4" },
              [
                _c(
                  "base-label",
                  { attrs: { for: "animal_type_" + _vm._uid } },
                  [_vm._v(" Animal type ")]
                ),
                _c("v-select", {
                  staticClass: "font-medium rounded text-gray-700 bg-white",
                  attrs: {
                    id: "animal_type_" + _vm._uid,
                    searchable: false,
                    clearable: false,
                    label: "name",
                    options: _vm.animalTypes,
                  },
                  model: {
                    value: _vm.animalType,
                    callback: function ($$v) {
                      _vm.animalType = $$v
                    },
                    expression: "animalType",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "w-full mb-4" },
          [
            _c("base-label", { attrs: { for: "breed_" + _vm._uid } }, [
              _vm._v(" Breed / sub-species "),
            ]),
            _c("base-input", {
              staticClass: "shadow-none",
              attrs: {
                id: "breed_" + _vm._uid,
                type: "text",
                placeholder: "Breed or species",
              },
              model: {
                value: _vm.animalCopy.race,
                callback: function ($$v) {
                  _vm.$set(_vm.animalCopy, "race", $$v)
                },
                expression: "animalCopy.race",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "w-full mb-4" },
          [
            _c("base-label", { attrs: { for: "gender_" + _vm._uid } }, [
              _vm._v(" Gender "),
            ]),
            _c("v-select", {
              staticClass: "font-medium rounded text-gray-700  bg-white",
              attrs: {
                id: "gender_" + _vm._uid,
                searchable: false,
                clearable: false,
                label: "name",
                loading: !_vm.genderOptions.length,
                options: _vm.genderOptions,
              },
              model: {
                value: _vm.animalGender,
                callback: function ($$v) {
                  _vm.animalGender = $$v
                },
                expression: "animalGender",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "w-full mb-4" },
          [
            _c("base-label", { attrs: { for: "ic_" + _vm._uid } }, [
              _vm._v(" Insurance company "),
            ]),
            _c("v-select", {
              staticClass: "font-medium rounded text-gray-700 bg-white",
              attrs: {
                id: "ic_" + _vm._uid,
                searchable: false,
                clearable: false,
                label: "name",
                options: _vm.companies,
                loading: !_vm.companies.length,
              },
              model: {
                value: _vm.insuranceCompany,
                callback: function ($$v) {
                  _vm.insuranceCompany = $$v
                },
                expression: "insuranceCompany",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "w-full mb-4" },
          [
            _c("base-label", { attrs: { for: "icNumber_" + _vm._uid } }, [
              _vm._v(" Insurance number "),
            ]),
            _c("base-input", {
              staticClass: "shadow-none",
              attrs: {
                id: "icNumber_" + _vm._uid,
                placeholder: "Insurance number or social security number",
              },
              model: {
                value: _vm.animalCopy.insurance,
                callback: function ($$v) {
                  _vm.$set(_vm.animalCopy, "insurance", $$v)
                },
                expression: "animalCopy.insurance",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "w-full mb-4" },
          [
            _c("base-label", { attrs: { for: "description_" + _vm._uid } }, [
              _vm._v(" Description "),
            ]),
            _c("textarea-input", {
              staticClass: "shadow-none",
              attrs: {
                id: "description_" + _vm._uid,
                placeholder: "Description / other information.",
              },
              model: {
                value: _vm.animalCopy.description,
                callback: function ($$v) {
                  _vm.$set(_vm.animalCopy, "description", $$v)
                },
                expression: "animalCopy.description",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "w-full flex justify-end py-4 space-x-2" },
          [
            !_vm.hideCancel
              ? _c(
                  "base-button",
                  {
                    attrs: {
                      type: "button",
                      color: "cancel",
                      disabled: _vm.loading,
                    },
                    nativeOn: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.cancel.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(" Cancel ")]
                )
              : _vm._e(),
            _c(
              "base-button",
              {
                staticClass: "w-1/6",
                attrs: {
                  type: "submit",
                  color: "primary",
                  loading: _vm.loading,
                },
              },
              [_vm._v(" Save ")]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }