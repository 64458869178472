<template>
  <div>
    <form
      class="flex flex-wrap"
      @submit.prevent="submit"
    >
      <div class="w-full mb-4">
        <base-label :for="`name_${_uid}`">
          Name 
        </base-label>
        <base-input
          :id="`name_${_uid}`"
          v-model="animalCopy.name"
          class="shadow-none"
          type="text"
          placeholder="Name of the animal"
        />
      </div>
      <div class="w-full mb-4">
        <base-label :for="`birthdate_${_uid}`">
          Birthdate
        </base-label>
        <Datepicker
          :id="`birthdate_${_uid}`"
          v-model="animalBirthdate"
          :format="'yyyy-MM-dd'"
          class="rounded border border-gray-light font-medium"
          :monday-first="true"
          :disabled-dates="disabledDates"
        />
      </div>
      <div
        v-if="showAnimalType"
        class="w-full mb-4"
      >
        <base-label :for="`animal_type_${_uid}`">
          Animal type
        </base-label>
        <v-select
          :id="`animal_type_${_uid}`"
          v-model="animalType"
          class="font-medium rounded text-gray-700 bg-white"
          :searchable="false"
          :clearable="false"
          label="name"
          :options="animalTypes"
        />
      </div>
      <div class="w-full mb-4">
        <base-label :for="`breed_${_uid}`">
          Breed / sub-species
        </base-label>
        <base-input
          :id="`breed_${_uid}`"
          v-model="animalCopy.race"
          class="shadow-none"
          type="text"
          placeholder="Breed or species"
        />
      </div>
      <div class="w-full mb-4">
        <base-label :for="`gender_${_uid}`">
          Gender
        </base-label>
        <v-select
          :id="`gender_${_uid}`"
          v-model="animalGender"
          class="font-medium rounded text-gray-700  bg-white"
          :searchable="false"
          :clearable="false"
          label="name"
          :loading="!genderOptions.length"
          :options="genderOptions"
        />
      </div>

      <div class="w-full mb-4">
        <base-label :for="`ic_${_uid}`">
          Insurance company
        </base-label>
        <v-select
          :id="`ic_${_uid}`"
          v-model="insuranceCompany"
          class="font-medium rounded text-gray-700 bg-white"
          :searchable="false"
          :clearable="false"
          label="name"
          :options="companies"
          :loading="!companies.length"
        />
      </div>
      <div class="w-full mb-4">
        <base-label :for="`icNumber_${_uid}`">
          Insurance number
        </base-label>
        <base-input
          :id="`icNumber_${_uid}`"
          v-model="animalCopy.insurance"
          class="shadow-none"
          placeholder="Insurance number or social security number"
        />
      </div>

      <div class="w-full mb-4">
        <base-label :for="`description_${_uid}`">
          Description
        </base-label>
        <textarea-input
          :id="`description_${_uid}`"
          v-model="animalCopy.description"
          class="shadow-none"
          placeholder="Description / other information."
        />
      </div>

      <div class="w-full flex justify-end py-4 space-x-2">
        <base-button
          v-if="!hideCancel"
          type="button"
          color="cancel"
          :disabled="loading"
          @click.prevent.native="cancel"
        >
          Cancel
        </base-button>

        <base-button
          type="submit"
          color="primary"
          class="w-1/6"
          :loading="loading"
        >
          Save
        </base-button>
      </div>
    </form>
  </div>
</template>
<script>
import Datepicker from 'vuejs-datepicker';
import '@/utils/helpers/time-helpers';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    Datepicker,
  },

  props: {
    animal: {
      type: Object,
      default: null,
    },
    showAnimalType: {
      type: Boolean,
      default: false,
    },
    hideCancel: {
      type: Boolean,
      default: false,
    },
    companies: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      animalCopy: {},
    };
  },

  computed: {
    ...mapGetters('animal', {
      genderOptions: 'getGenderOptions',
      animalTypes: 'getAnimalTypes',
    }),

    disabledDates() {
      return {
        from: new Date(),
      };
    },

    animalType: {
      get() {
        return this.animalTypes.find(
          type => type.id === this.animalCopy.type_id
        );
      },
      set(type) {
        this.animalCopy.type_id = type.id;
      },
    },
    animalGender: {
      get() {
        return this.genderOptions.find(
          gender => gender.value === this.animalCopy.gender
        );
      },
      set(gender) {
        this.animalCopy.gender = gender.value;
      },
    },
    animalBirthdate: {
      get() {
        return this.animalCopy.birthdate;
      },
      set(dt) {
        const date = this.$formatDate(dt);
        this.animalCopy.birthdate = date;
      },
    },

    insuranceCompany: {
      get() {
        return (
          this.companies.find(ic => ic.id === this.animalCopy.insurance_id) ||
          null
        );
      },
      set(ic) {
        this.animalCopy.insurance_id = ic.id;
      },
    },
  },

  watch: {
    animal() {
      if (this.animal?.id) {
        this.animalCopy = { ...this.animal };
      }
    },
  },

  mounted() {
    if (this.animal?.id) {
      this.animalCopy = { ...this.animal };
    }

    if (this.showAnimalType && !this.animalTypes?.length) {
      this.fetchAnimalTypes();
    }
  },

  methods: {
    ...mapActions('animal', [
      'postAnimalData',
      'fetchAnimal',
      'fetchAnimalTypes',
    ]),

    submit() {
      this.loading = true;

      this.$emit('submit', {
        animal: this.animalCopy,
        done: (success, data) => {
          if (success) {
            this.$emit('cancelEdit', data);
          }
          this.loading = false;
        },
      });
    },

    cancel() {
      this.fetchAnimal(this.animalCopy.id).then(res => {
        this.$emit('cancelEdit', res);
      });
    },
  },
};
</script>
